<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg visitor-management-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: routeHelp,
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "reception-desks",
              "add-new-visitor"
            )
          }}
        </div>
        <template v-slot:right>
          <icon class="save-icon" icon="#cx-hea1-save" @click="submitForm" />
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm v-if="visitor" id="visitorForm" class="form" novalidate>
      <ul class="clebex-item-section">
        <!-- email -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="email">{{
              displayLabelName("visitor-management", "reception-desks", "email")
            }}</label>
            <Field
              name="email"
              as="input"
              type="text"
              id="email"
              v-model="visitor.email"
              v-on:blur="checkEmail"
              :disabled="!isVisitor"
            />
          </div>
        </li>
        <!-- first name -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="first_name"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "first-name"
                  )
                }}
                <span>*</span>
              </label>
              <Field
                name="first_name"
                as="input"
                type="text"
                rules="required"
                id="first_name"
                v-model="visitor.first_name"
                :disabled="isDisabled('first_name')"
              />
            </div>
          </div>
        </li>
        <!-- last name -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="last_name"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "last-name"
                  )
                }}
                <span>*</span>
              </label>
              <Field
                name="last_name"
                as="input"
                type="text"
                rules="required"
                id="last_name"
                v-model="visitor.last_name"
                :disabled="isDisabled('last_name')"
              />
            </div>
          </div>
        </li>
        <!-- middle name -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.middleName"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="middleNameId">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "middle-name"
                  )
                }}</label
              >
              <Field
                name="middle_name"
                as="input"
                type="text"
                id="middleNameId"
                v-model="visitor.middle_name"
                :disabled="isDisabled('middle_name')"
              />
            </div>
          </div>
        </li>
        <!-- phone -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "phone"
                  )
                }}
              </label>
              <Field
                name="phone"
                as="input"
                type="tel"
                id="phoneId"
                autocomplete="off"
                v-model="visitor.phone"
                :disabled="isDisabled('phone')"
              />
            </div>
          </div>
        </li>
        <!-- city -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.city"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cityId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "city"
                )
              }}</label>
              <Field
                name="city"
                as="input"
                type="text"
                id="cityId"
                v-model="visitor.city"
                :disabled="isDisabled('city')"
              />
            </div>
          </div>
        </li>
        <!-- company -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.company"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="company">{{
              displayLabelName(
                "visitor-management",
                "reception-desks",
                "company"
              )
            }}</label>
            <Field
              name="company"
              as="input"
              type="text"
              rules="required"
              id="company"
              v-model="visitor.company"
              :disabled="isDisabled('company')"
            />
          </div>
        </li>
        <!-- department -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.department"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="departmentId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "department"
                )
              }}</label>
              <Field
                name="department"
                as="input"
                type="text"
                id="departmentId"
                v-model="visitor.department"
                :disabled="isDisabled('department')"
              />
            </div>
          </div>
        </li>
        <!-- passport -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.passport"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="passportId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "passport"
                )
              }}</label>
              <Field
                name="passport"
                as="input"
                type="text"
                id="passportId"
                v-model="visitor.passport"
                :disabled="isDisabled('passport')"
              />
            </div>
          </div>
        </li>
        <!-- licence plate -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.licensePlate"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "license-plate"
                )
              }}</label>
              <Field
                name="license_plate"
                as="input"
                type="text"
                id="licensePlateId"
                v-model="visitor.license_plate"
                :disabled="isDisabled('license_plate')"
              />
            </div>
          </div>
        </li>
        <!-- language -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.language"
        >
          <div
            @click="openLanguage(language, routeAddVisitorLanguage)"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label class="clebex-section-input-label" style="cursor: pointer;"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "language"
                  )
                }}
                <span>*</span></label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
        </li>
        <!-- assignable containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="
            userFormFields.addAssignableContainersToUser &&
              $route.name !== 'r_my-visitors-reception-desk-add-visitor'
          "
        >
          <router-link
            :to="{ name: 'r_reception-desk-add-containers-to-visitor' }"
            class="clebex-item-content-wrapper"
            v-if="isVisitor"
          >
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "containers"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map((item) => item.name).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "containers"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "containers"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map((item) => item.name).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "containers"
                )
              }}
            </span>
          </div>
        </li>

        <!-- Disability -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="disability"
                    type="checkbox"
                    v-model="visitor.disability"
                    :disabled="isDisabled('disability')"
                  />
                  <label for="disability"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "disability"
                  )
                }}
              </dd>
            </dl>
            <Field
              name="disability"
              as="input"
              type="hidden"
              :disabled="isDisabled('disability')"
              v-model="visitor.disability"
            />
          </div>
        </li>
      </ul>

      <!-- Visit details -->
      <ul class="clebex-item-section" v-if="visit">
        <!-- Date from - date to -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour ffrom - hour to -->
        <li class="clebex-item-section-item time">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
        <!-- host -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="$route.name !== 'r_my-visitors-reception-desk-add-visitor'"
        >
          <router-link
            :to="{ name: 'r_reception-desk-add-visitor-select-host' }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "host"
                )
              }}</label>
              <p class="text-label ellipsis">
                <template v-if="visit.host">
                  {{ selectedHost }}
                </template>
                <template v-else>
                  {{
                    displayLabelName(
                      "visitor-management",
                      "reception-desks",
                      "host"
                    )
                  }}
                </template>
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
        </li>
        <!-- notes -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="notes">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "notes"
                )
              }}</label>
              <Field
                name="notes"
                as="input"
                type="text"
                id="notesId"
                v-model="visit.notes"
              />
            </div>
          </div>
        </li>
      </ul>
    </VeeForm>

    <template v-slot:footer>
      <nav class="qr-codes-footer actions-menu theme-gray">
        <ul class="actions-list">
          <li></li>
          <li v-if="$route.name !== 'r_my-visitors-reception-desk-add-visitor'">
            <button @click="goToImport" class="action-btn">
              {{
                displayLabelName("visitor-management.reception-desks.import")
              }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapGetters, mapActions, mapState } from "vuex";
import { formatDate } from "@/services/helpers";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "AddEditVisitor",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
  },
  data() {
    return {
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      showDateTimePicker: true,
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      isVisitor: true,
      disabled: {},
      helpSlug: "visitor-new",
    };
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchInitData();
    this.setSelectedLanguage(null);
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visitorManagement", [
      "visitor",
      "selectedContainers",
      "allowedContainers",
      "receptionDeskInfo",
    ]),
    routeEditVisitor() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor";
      }
      return "r_reception-desk-edit-visitor";
    },
    ...mapState("visit", ["visit"]),
    ...mapState("user", ["userList", "userFormFields", "userData"]),
    ...mapState("language", ["selectedLanguage"]),
    ...mapState("companyProfile", ["companyProfile"]),
    routeAddVisitorLanguage() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-add-visitor-select-language";
      }
      return "r_reception-desk-add-visitor-select-language";
    },
    routeHelp() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-visitor-add-help-online";
      }
      return "r_visitor-add-help-online";
    },
    receptionDeskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    },
    selectedHost() {
      if (
        this.visit.host &&
        this.visit.host.first_name &&
        this.visit.host.last_name
      ) {
        return `${this.visit.host.first_name} ${this.visit.host.last_name}`;
      }
      return null;
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else if (this.receptionDeskInfo && this.receptionDeskInfo.language) {
        return this.receptionDeskInfo.language;
      } else if (this.companyProfile && this.companyProfile.language) {
        return this.companyProfile.language;
      } else {
        return null;
      }
    },
  },
  watch: {
    userList: {
      handler(hosts) {
        this.$store.commit(
          "visit/setVisit",
          {
            ...this.visit,
            host:
              hosts && hosts.length
                ? hosts.find((el) => el.id == this.userData.data.id)
                : null,
          },
          { root: true }
        );
      },
    },
    languages: {
      handler(value) {
        this.$store.commit(
          "visitorManagement/setVisitor",
          {
            ...this.visitor,
            language: value.data && value.data.length ? value.data[0] : null,
          },
          { root: true }
        );
      },
    },
  },
  methods: {
    ...mapActions("visitorManagement", [
      "postVisitor",
      "getAllowedContainers",
      "setSelectedContainer",
      "checkIfExist",
      "setSelectedContainers",
    ]),
    ...mapActions("visit", ["postVisit"]),
    ...mapActions("user", ["getCompanyUserList"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");
      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    fetchInitData() {
      const promises = [];
      if (!this.userList) {
        promises.push(this.getCompanyUserList());
      }
      const containersPromise = this.getAllowedContainers(
        this.receptionDeskId
      ).then((allowedContainers) => {
        if (this.receptionDeskInfo.user_container_id) {
          let idx = allowedContainers.findIndex(
            (allowed) => allowed.id === this.receptionDeskInfo.user_container_id
          );
          this.setSelectedContainer(allowedContainers[idx]);
        }
      });
      promises.push(containersPromise);

      return Promise.all(promises).then(() => {
        this.$store.commit(
          "visitorManagement/setVisitor",
          {
            first_name: "",
            last_name: "",
            middle_name: "",
            phone: "",
            username: "",
            city: "",
            company: "",
            email: "",
            department: "",
            passport: "",
            license_plate: "",
            language: this.selectedLanguage
              ? this.selectedLanguage
              : this.languages &&
                this.languages.data &&
                this.languages.data.length
              ? this.languages.data[0]
              : null,
            booking_id: null,
            reception_desk_id: this.receptionDeskId,
            container_ids: [],
            disability: false,
          },
          { root: true }
        );
        this.$store.commit("visit/setVisit", {
          // host: this.userData.data,
          host: this.userList && this.userList.length ? this.userList[0] : null,
          datetime_from: this.selectedDateFrom,
          datetime_to: this.selectedDateTo,
          notes: "",
        });
      });
    },
    submitForm() {
      const payload = {
        ...this.visitor,
        language_id: this.language.id,
        container_ids: this.selectedContainers
          ? [...this.selectedContainers.map((c) => c.id)]
          : [],
      };

      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      /**
       * First save Visitor because we need visitor id then save Visit
       */
      this.postVisitor(payload)
        .then((response) => {
          if (!response || !response.id) {
            return null;
          }
          const payload = {
            datetime_from: dateTimeFrom,
            datetime_to: dateTimeTo,
            host_id: this.visit.host.id,
            reception_desk_id: this.receptionDeskId,
            visitor_id: response.id,
            notes: this.visit.notes,
          };

          return this.postVisit(payload);
        })
        .then((response) => {
          if (response) {
            this.$store.commit("visitorManagement/setRefreshVisitors", true, {
              root: true,
            });

            this.$router.push({
              name: this.backLinkName,
              params: this.$route.params,
            });
          }
        });
    },
    checkEmail() {
      if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.visitor.email)
      ) {
        return true;
      }
      this.checkIfExist().then((response) => {
        if (!response) {
          return;
        }

        this.$router.push({
          name: this.routeEditVisitor,
          params: { visitorId: response.id },
        });

        // If user is found and it's a normal user (not visitor) disable user specific inputs
        // this.isVisitor = response.is_visitor;
        // this.visitor.language = this.languages.data.find(
        //   lang => (lang.id = response.language_id)
        // );
        // if (
        //   response.assigned_containers &&
        //   response.assigned_containers.length
        // ) {
        //   this.setSelectedContainers(response.assigned_containers);
        // }
        // this.disabled = { ...response };
      });
    },
    isDisabled(field) {
      return (
        !this.isVisitor && this.disabled[field] && this.disabled[field].length
      );
    },
    goToImport() {
      this.$router.push({ name: "r_reception-desk-add-visitor-import" });
    },
  },
  beforeUnmount() {
    this.$store.commit("visitorManagement/setVisitor", null, { root: true });
    this.$store.commit("visit/setVisit", null, { root: true });
    this.$store.commit("visitorManagement/setSelectedContainers", null, {
      root: true,
    });
  },
  mixins: [languagesMixin, helpOnlineMixin],
};
</script>
